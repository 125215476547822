import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { NewAPIMentoringSheet } from 'types';
import { formatDate } from 'utils';

type AddMentoringSheetDialogProps = {
  open: boolean;
  meetingDate: Date;
  setMeetingDate: (date: Date) => void;
  create: (payload: { meetingDate: Date }) => void;
  onClose: () => void;
};
export const AddMentoringSheetDialog: React.FC<
  AddMentoringSheetDialogProps
> = ({ open, meetingDate, setMeetingDate, create, onClose }) => {
  const meetingDateString = formatDate(meetingDate, '%Y-%m-%d');
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>G-POP&reg;シート新規追加</DialogTitle>
      <DialogContent>
        ミーティング日:{' '}
        <TextField
          sx={{ verticalAlign: 'middle', ml: 1 }}
          type="date"
          value={meetingDateString}
          size="small"
          onChange={e => setMeetingDate(new Date(e.target.value))}
        />
        <DialogContentText variant="caption" sx={{ mt: 1 }}>
          表示中のシートの"資産時価総額目標"、"フィナンシャルゴール"を。また、表示中の"次のアクション"を"やると決まったこと"にコピーします。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button
          variant="contained"
          onClick={() => {
            create({ meetingDate });
            onClose();
          }}
        >
          新規追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};
