import { useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import {
  AddMentoringSheetDialog,
  AlignCenterGrid,
  DeleteMentoringSheetDialog,
  Loading,
  MentoringSheetForm,
  SelectSheet,
} from 'components';
import { useToggle } from 'hooks';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { clientSlice, createMentoringSheetAsync, removeMentoringSheetAsync, replaceAssetAsync, replaceMentoringSheetAsync, selectClientError, selectClientMentoringSheet, selectClientMentoringSheets, selectClientStatus } from 'features';
import { NewAPIMentoringSheetWithServerValues } from 'types';
import { useAppContext } from 'contexts';

export const AdminMentoringPage = () => {
  const { clientId } = useParams() as { clientId: string };
  const dispatch = useAppDispatch();
  const clientStatus = useAppSelector(selectClientStatus);
  const clientError = useAppSelector(selectClientError);
  const sheets = useAppSelector(selectClientMentoringSheets);
  const { draftMentoringSheet, setDraftMentoringSheet } = useAppContext();

  const [openAddMentoringSheetDialog, toggleOpenAddMentoringSheetDialog] =
    useToggle(false);
  const [openDeleteMentoringSheetDialog, toggleOpenDeleteMentoringSheetDialog] =
    useToggle(false);
  const [newSheetMeetingDate, setNewSheetMeetingDate] = useState<Date>(() => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  });

  if (clientStatus === 'pending') {
    return <Loading />;
  }
  if (clientStatus === 'rejected') {
    return <div>{clientError}</div>;
  }
  if (!draftMentoringSheet) {
    return <AlignCenterGrid>
      <Box display="flex" sx={{ px: 2 }}>
        <Typography>シートがありません</Typography>
        <OpenAddNewSheetDialogButton
          onClick={() => {
            toggleOpenAddMentoringSheetDialog();
          }}
        />
      </Box>
      <AddMentoringSheetDialog
        open={openAddMentoringSheetDialog}
        meetingDate={newSheetMeetingDate}
        setMeetingDate={d => setNewSheetMeetingDate(d)}
        create={({ meetingDate }) => dispatch(createMentoringSheetAsync({
          client_id: clientId,
          data: {
            meetingDate,
            netWorthGoal: 0,
            financialGoal: "",
            todo: "",
           },
        }))}
        onClose={toggleOpenAddMentoringSheetDialog}
      />
    </AlignCenterGrid>
  }
  return (
    <AlignCenterGrid>
      <Box display="flex" sx={{ px: 2 }}>
        <SelectSheet
          sheets={sheets}
          sheet={draftMentoringSheet}
          selectSheet={(sheet: NewAPIMentoringSheetWithServerValues) => {
            dispatch(clientSlice.actions.selectMentoringSheet(sheet));
          }}
        />
        <OpenAddNewSheetDialogButton
          onClick={() => {
            toggleOpenAddMentoringSheetDialog();
          }}
        />
        <PublishButton
          isDraft={draftMentoringSheet.isDraft}
          onClick={() =>
            dispatch(replaceMentoringSheetAsync({
              client_id: clientId,
              id: draftMentoringSheet.id,
              data: {
                ...draftMentoringSheet,
                isDraft: !draftMentoringSheet.isDraft,
              }
            }))
          } />
      </Box>
      <MentoringSheetForm
        draftSheet={draftMentoringSheet}
        setDraftSheet={setDraftMentoringSheet}
        save={() => {
          const { id, ...data } = draftMentoringSheet;
          dispatch(replaceMentoringSheetAsync({
            id,
            client_id: clientId,
            data,
          }))
        }}
      />
      <hr style={{ marginTop: 24 }} />
      <OpenDeleteSheetDialogButton
        onClick={toggleOpenDeleteMentoringSheetDialog}
      />
      <AddMentoringSheetDialog
        open={openAddMentoringSheetDialog}
        meetingDate={newSheetMeetingDate}
        setMeetingDate={d => setNewSheetMeetingDate(d)}
        create={({ meetingDate }) => dispatch(createMentoringSheetAsync({
          client_id: clientId,
          data: {
            meetingDate,
            netWorthGoal: draftMentoringSheet.netWorthGoal,
            financialGoal: draftMentoringSheet.financialGoal,
            todo: draftMentoringSheet.nextActions,
           },
        }))}
        onClose={toggleOpenAddMentoringSheetDialog}
      />
      <DeleteMentoringSheetDialog
        open={openDeleteMentoringSheetDialog}
        deleteSheet={() => {
          if (draftMentoringSheet) {
            dispatch(removeMentoringSheetAsync({ client_id: clientId, id: draftMentoringSheet.id }))
          }
        }
        }
        onClose={toggleOpenDeleteMentoringSheetDialog}
      />
    </AlignCenterGrid>
  );
};

const PublishButton = ({
  isDraft,
  onClick,
}: {
  isDraft: boolean;
  onClick: () => void;
}) => (
  <Button
    color="secondary"
    variant="contained"
    size="small"
    sx={{ mx: 1 }}
    onClick={onClick}

  >
    {isDraft ? "お客様に反映する" : "下書きに戻す"}
  </Button>
);
const OpenAddNewSheetDialogButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="contained"
    color="secondary"
    size="small"
    sx={{ mx: 1, minWidth: 24 }}
    onClick={onClick}
  >
    新しいG-POP&reg;シートを作成
  </Button>
);

const OpenDeleteSheetDialogButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="contained"
    color="warning"
    size="large"
    sx={{ mx: 2, minWidth: 24, mb: 12 }}
    onClick={onClick}
  >
    シートを削除
  </Button>
);

const EmptySheetPage = ({ }) => { };
