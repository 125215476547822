import { createSlice } from "@reduxjs/toolkit";
import { getFinancialProducts } from "api";
import { RootState } from "app/store";
import { createAppAsyncThunk } from "app/withTypes";
import { getToken } from "features/auth";
import { FinancialProduct } from "types";

type FinancialProductState = {
  status: 'idle' | 'pending' | 'succeeded' | 'rejected',
  error: string | null,
  products: FinancialProduct[],
}

const initialState: FinancialProductState = {
  status: 'idle',
  error: null,
  products: [],
}

export const fetchFinancialProductsAsync = createAppAsyncThunk(
  'financialProduct/fetchFinancialProducts',
  async () => {
    const token = await getToken();
    const response = await getFinancialProducts(token);
    return response;
  }
)

export const financialProductSlice = createSlice({
  name: 'financialProduct',
  initialState,
  reducers: {
    reset(state) {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinancialProductsAsync.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchFinancialProductsAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchFinancialProductsAsync.rejected, (state) => {
        state.status = 'rejected';
      })
  }
})

export const selectFinancialProducts = (state: RootState) => state.financialProduct.products;
