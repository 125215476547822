import { Box, Typography, Button, TextField } from '@mui/material';
import { MainMenuTabs } from 'components';
import { useInsightSheet } from 'hooks';

export const InsightsPage = () => {
  const { insight, newInsightIframe, onChangeNewIframe, save } =
    useInsightSheet('common');

  return (
    <>
      <MainMenuTabs tabValue='/insights' />

      <Box sx={{ px: 4, pt: 4, pb: 8 }}>
        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
          共通投資戦略スライド
        </Typography>
        <Typography component="p" variant="caption" sx={{ mb: 2 }}>
          顧客ごとの戦略くんは顧客の個別ページで変更できます。
        </Typography>
        <TextField
          size="small"
          value={newInsightIframe || ''}
          label="iframe"
          placeholder='<iframe class="speakerdeck-iframe" frameborder="0" src="https:/...></iframe>'
          onChange={e => onChangeNewIframe(e.target.value)}
          fullWidth
        />
        <Box sx={{ mt: 4 }}>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={save}
          >
            変更を保存する
          </Button>
        </Box>
      </Box>
    </>
  );
};
