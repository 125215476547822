const API_URL = process.env.REACT_APP_PORTFOLIOH_API_URL as string;

export const getTokenWithEmailOrNameAndPassword = async (
  emailOrName: string,
  password: string
) => {
  const payload = emailOrName.includes('@')
    ? { email: emailOrName, password }
    : { name: emailOrName, password };
  const response = await fetch(`${API_URL}/auth/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    const responseJson = await response.json();
    return responseJson;
  }
  throw new Error((await response.json()).message);
};

export const getCustomToken = async (id_token: string) => {
  const response = await fetch(`${API_URL}/auth/custom-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id_token }),
  });
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json();
};


export const getMembersByRole = async (
  role: string | undefined,
  token: string
) => {
  const roleQuery = role ? `?role=${encodeURIComponent(role)}` : '';
  const response = await fetch(`${API_URL}/members${roleQuery}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json();
};

