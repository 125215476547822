import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';

export const useRedirectIfAdmin = (redirectPath = '/clients') => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  useEffect(() => {
    currentUser?.getIdTokenResult(true)?.then(idTokenResult => {
      const _isAdmin = idTokenResult.claims.admin as boolean | undefined;
      if (_isAdmin) {
        navigate(redirectPath);
      }
    });
  }, [currentUser?.uid]);
};
