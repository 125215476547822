import { Grid, Box, Typography } from '@mui/material';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import EmojiEmotionsRoundedIcon from '@mui/icons-material/EmojiEmotionsRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import { AlignCenterGrid } from 'components/AlignCenterGrid';

import { DashboardContentBox } from './DashboardContentBox';
import { NetWorthProgressBar } from './NetWorthProgressBar';
import { InsightSlide } from './InsightSlide';
import { NewAPIMentoringSheet, WithId, WithTimestamp } from 'types';

interface DashboardPresentationProps {
  sheet: WithTimestamp<WithId<NewAPIMentoringSheet>>;
  totalMarketValueDomestic: number;
  personalizedInsightSpeakerDeckIFrame: string;
  commonInsightSpeakerDeckIFrame: string;
  clientName: string;
}

export const DashboardPresentation = ({
  sheet,
  totalMarketValueDomestic,
  personalizedInsightSpeakerDeckIFrame,
  commonInsightSpeakerDeckIFrame,
  clientName,
}: DashboardPresentationProps) => (
  <>
    <AlignCenterGrid>
      <Grid container spacing={3} sx={{ px: 2, py: 5 }}>
        <Grid item xs={12}>
          <DashboardContentBox
            title="資産時価総額目標進捗 (円)"
            icon={<PaidRoundedIcon style={{ color: 'hsl(0, 90%, 80%)' }} />}
            color="hsl(0, 90%, 80%)"
          >
            <NetWorthProgressBar
              netWorthGoal={sheet.netWorthGoal}
              currentNetWorth={totalMarketValueDomestic}
            />
          </DashboardContentBox>
        </Grid>
        <Grid item xs={12}>
          <DashboardContentBox
            title="フィナンシャルゴール"
            icon={<FlagRoundedIcon style={{ color: 'hsl(40, 90%, 80%)' }} />}
            color="hsl(40, 90%, 80%)"
          >
            {sheet.financialGoal}
          </DashboardContentBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardContentBox
            title="今月のアクション"
            icon={<TaskRoundedIcon style={{ color: 'hsl(200, 90%, 80%)' }} />}
            color="hsl(200, 90%, 80%)"
          >
            {sheet.nextActions}
          </DashboardContentBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardContentBox
            title="メンターからのコメント"
            icon={
              <EmojiEmotionsRoundedIcon
                style={{ color: 'hsl(240, 90%, 80%)' }}
              />
            }
            color="hsl(240, 90%, 80%)"
          >
            {sheet.mentorComment}
          </DashboardContentBox>
        </Grid>
        <Grid item xs={12}>
          <DashboardContentBox
            title="投資戦略"
            icon={
              <InsightsRoundedIcon style={{ color: 'hsl(300, 90%, 80%)' }} />
            }
            color="hsl(300, 90%, 80%)"
          >
            {personalizedInsightSpeakerDeckIFrame.length > 0 && (
              <Box>
                <Typography sx={{ fontWeight: 'bold', color: '#555', my: 2 }}>
                  {clientName}様専用
                </Typography>
                <InsightSlide
                  speakerDeckIFrame={personalizedInsightSpeakerDeckIFrame}
                />
              </Box>
            )}
            {commonInsightSpeakerDeckIFrame.length > 0 && (
              <Box>
                <Typography sx={{ fontWeight: 'bold', color: '#555', my: 2 }}>
                  共通
                </Typography>
                <InsightSlide
                  speakerDeckIFrame={commonInsightSpeakerDeckIFrame}
                />
              </Box>
            )}
          </DashboardContentBox>
        </Grid>
      </Grid>
    </AlignCenterGrid>
  </>
);
