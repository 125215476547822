import React from "react";
import { IAsset, NewAPIMentoringSheet, WithId } from "types";

const AppContext = React.createContext<{
  draftMentoringSheet: WithId<NewAPIMentoringSheet> | null;
  setDraftMentoringSheet: React.Dispatch<React.SetStateAction<WithId<NewAPIMentoringSheet> | null>>
  draftAsset: IAsset | null,
  setDraftAsset: React.Dispatch<React.SetStateAction<IAsset | null>>
}>({
  draftMentoringSheet: null,
  setDraftMentoringSheet: () => { },
  draftAsset: null,
  setDraftAsset: () => { }
});

export const useAppContext = () => React.useContext(AppContext);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [draftMentoringSheet, setDraftMentoringSheet] = React.useState<WithId<NewAPIMentoringSheet> | null>(null);
  const [draftAsset, setDraftAsset] = React.useState<IAsset | null>(null);
  return (
    <AppContext.Provider
      value={{
        draftMentoringSheet,
        setDraftMentoringSheet,
        draftAsset,
        setDraftAsset
      }}
    >{children}</AppContext.Provider>
  )
}
