import { useState, useEffect } from 'react';
import { FamiliesTable } from './FamiliesTable';
import { useAuth, useToggle } from 'hooks';
import { Box, IconButton, Tooltip } from '@mui/material';
import { EditFamilyDialog } from './EditFamilyDialog';
import EditIcon from '@mui/icons-material/Edit';
import { MainMenuTabs, Loading } from 'components';
import { Family } from 'types/family';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { replaceFamily, selectFamilies, selectFamilyError, selectFamilyStatus } from 'features';

export const FamiliesPage = () => {
  const dispatch = useAppDispatch();
  const families = useAppSelector(selectFamilies);
  const familyStatus = useAppSelector(selectFamilyStatus);
  const familyError = useAppSelector(selectFamilyError);
  const [selectedFamily, setSelectedFamily] = useState<Family | null>(null);
  const [isOpenEditDialog, toggleIsOpenEditDialog] = useToggle(false);

  const handleSelectionChange = (family: Family) => {
    if (selectedFamily && family.id === selectedFamily.id) {
      setSelectedFamily(null); // すでに選択されている家族を選択した場合、選択を解除する
    } else {
      setSelectedFamily(family); // 新しく選択された家族を設定する
    }
  };

  const handleOpenEditDialog = (family: Family) => {
    setSelectedFamily(family);
    toggleIsOpenEditDialog();
  };

  const handleCloseEditDialog = () => {
    setSelectedFamily(null);
    toggleIsOpenEditDialog();
  };

  const handleEdit = async (formFamily: Family) => {
    if (selectedFamily) {
      try {
        dispatch(replaceFamily({ id: selectedFamily.id, data: formFamily }));
      } catch (e) {
        console.log(e);
      }
    } else {
      console.error('No family selected.');
    }
  };

  return (
    <>
      <MainMenuTabs tabValue='/families' />
      <Box sx={{ px: 4, py: 1 }}>
        <div style={{ height: '20px' }}>
          {selectedFamily && (
            <Tooltip title="顧客を編集">
              <IconButton
                aria-label="download"
                onClick={() => handleOpenEditDialog(selectedFamily)}
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {familyStatus === 'pending' ? (
          <Loading />
        ) : familyStatus === 'rejected' ? (
          familyError
        ) : (
          <FamiliesTable
            families={families ?? []}
            selectedFamily={selectedFamily}
            onSelectionChangeRequest={handleSelectionChange}
          />
        )}
        {isOpenEditDialog && selectedFamily && (
          <EditFamilyDialog
            open={isOpenEditDialog}
            onClose={handleCloseEditDialog}
            family={selectedFamily}
            onSave={handleEdit}
            status={familyStatus}
            error={familyError}
          />
        )}
      </Box>
    </>
  );
};
