import React from 'react';
import {
  Box,
  Stack,
  Link,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { useUpdateEmail } from './hooks';
import { PasswordTextField } from 'parts/PasswordTextField';
import { useAuth } from 'hooks';
import { Loading } from 'components/Loading';
import { Header } from 'components/Header';
import { AdminHeader } from 'pages/RootLayout';

export const UpdateEmailPage = () => {
  const { currentUser } = useAuth();
  const {
    password,
    newEmail,
    errorMessage,
    errorEmailMessage,
    errorPasswordMessage,
    success,
    update,
    onPasswordChange,
    onNewEmailChange,
    isLoading,
  } = useUpdateEmail();
  if (currentUser === undefined) {
    return (
      <>
        <Header title="" />
        <AdminHeader />
        <Loading />
      </>
    );
  }
  if (currentUser === null) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <>
      <Header title="" />
      <AdminHeader />
      <Box display="flex" justifyContent="center" sx={{ minHeight: '60vh' }}>
        <Stack
          spacing={2}
          sx={{ width: 330 }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h6" component="h2" sx={{ my: 8 }}>
            メールアドレスの変更
          </Typography>
          <PasswordTextField
            value={password}
            id="password"
            onChange={onPasswordChange}
            label="現在のパスワード"
            error={errorPasswordMessage.length > 0}
            helperText={errorPasswordMessage}
            fullWidth
            variant="standard"
            autoComplete="current-password"
            sx={{ pb: 4 }}
          />
          <TextField
            value={newEmail}
            id="new-email"
            onChange={onNewEmailChange}
            label="新しいメールアドレス"
            error={errorEmailMessage.length > 0}
            helperText={errorEmailMessage}
            fullWidth
            variant="standard"
            autoComplete="none"
            sx={{
              pb: 8,
              '& .MuiFormHelperText-root': {
                maxWidth: 'inherit',
              },
            }}
          />
          {errorMessage.length > 0 && (
            <Typography color="error.main" variant="caption">
              {errorMessage}
            </Typography>
          )}
          {/* change into navybutton */}
          {!success ? (
            <>
              <Button variant="contained" onClick={update} size="large">
                {isLoading ? (
                  <CircularProgress sx={{ color: 'white' }} />
                ) : (
                  '変更確認メールを送信する'
                )}
              </Button>
              <Link
                component={RouterLink}
                to={'/' + currentUser.uid}
                replace={false}
                variant="body2"
              >
                今は変更しない
              </Link>
            </>
          ) : (
            <div>
              <Typography variant="body2" sx={{ mb: 2 }}>
                新しいメールアドレスに確認メールを送りました。届いた確認メールを開いて、確認をすると変更が完了します。
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                見つからない場合、
                スパムフォルダーの中も含めて、noreply@system.pragmaworks.co.jp
                から届いているメールをお探しください。
              </Typography>
              <Link href={'/' + currentUser.uid}>トップページに移動する</Link>
            </div>
          )}
        </Stack>
      </Box>
    </>
  );
};
