import { FinancialProduct } from "types";

const API_URL = process.env.REACT_APP_PORTFOLIOH_API_URL as string;
export const getFinancialProducts = async (token: string) => {
  const response = await fetch(`${API_URL}/financial-products`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<FinancialProduct[]>;
};
