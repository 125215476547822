import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import {clientSlice, familySlice, financialProductSlice} from "../features";
import { createLogger } from 'redux-logger'

const logger = createLogger({});
export const store = configureStore({
  reducer: {
    client: clientSlice.reducer,
    family: familySlice.reducer,
    financialProduct: financialProductSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_FIREBASE_PROJECT_ID === "pw-banto-portfolio-dev" ?
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger)
    :
    getDefaultMiddleware({
      serializableCheck: false
    }),
})

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk = ThunkAction<void, RootState, unknown, Action>
