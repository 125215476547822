import { useAppSelector } from 'app/hooks';
import { Loading, ReportPresentation } from 'components';
import { selectFamily, selectFamilyAssets, selectFamilyHistoricalAggregationByAssetTypeMonthly, selectFamilyStatus } from 'features';
import {
  useExchangeRates,
} from 'hooks';

export const AdminFamilyReportPage = () => {
  const family = useAppSelector(selectFamily);
  const familyStatus = useAppSelector(selectFamilyStatus);
  const familyAssets = useAppSelector(selectFamilyAssets);
  const familyHistoricalAggregationByAssetTypeMonthly = useAppSelector(selectFamilyHistoricalAggregationByAssetTypeMonthly);
  const exchangeRates = useExchangeRates();
  if (familyStatus === 'idle' || familyStatus === 'pending' || family === null) {
    return <Loading />;
  }
  return (
    <ReportPresentation
      clientOrFamily={family}
      assets={familyAssets}
      barData={familyHistoricalAggregationByAssetTypeMonthly}
      exchangeRates={exchangeRates}
    />
  );
};


