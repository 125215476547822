import { httpsCallable } from 'firebase/functions';

import { functions } from 'initFirebase';
import { ClientAssetHistoryAggregationByAssetType, DraftClient, FinancialSummary, IAsset, IManualInput, NewApiClient, NewAPIMentoringSheetWithServerValues, NewAPIMentoringSheetStorePayload, PostClientMentoringSheetPayload } from 'types';

const API_URL = process.env.REACT_APP_PORTFOLIOH_API_URL as string;

type CreateClientInput = {
  email: string;
  name: string;
  password: string;
  familyId: string | undefined;
  familyName: string | undefined;
  status: string;
  clientBillingId: string;
};

type CreateClientResult = {
  success: boolean;
};

const createNewAccount = httpsCallable<CreateClientInput, CreateClientResult>(
  functions,
  'createNewAccount'
);

// TODO use API endpoint
export const addClient = async (client: {
  name: string;
  email: string;
  password: string; // for new client
  familyName?: string;
  familyId?: string;
  status: string;
  clientBillingId: string;
}) => {
  try {
    const { data } = await createNewAccount({
      email: client.email,
      password: client.password!,
      name: client.name,
      familyId: client.familyId,
      familyName: client.familyName,
      status: client.status,
      clientBillingId: client.clientBillingId,
    });
    if (!data.success) {
      throw new Error('クライアントの追加に失敗しました。');
    }
    return data;
  } catch (e) {
    throw new Error('クライアントの追加に失敗しました。');
  }
};


export const getClients = async ({token, familyId}:{token: string, familyId?: string}) => {
  const response = await fetch(`${API_URL}/clients${familyId ? `?family_id=${familyId}` : ''}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<NewApiClient[]>;
};

export const getClientById = async ({id, token,}: {id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<NewApiClient>;
}

export const putClientById = async ({
  id,
  data,
  token,
}: {
  id: string;
  data: DraftClient;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<NewApiClient>;
};

export const getClientFinancialSummary = async ({id, token}: {id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${id}/assets/summary`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<FinancialSummary>;
}



export const getAssetsByClientId = async ({
  clientId,
  token,
}: {
  clientId: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${clientId}/assets`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<IAsset[]>;
};

export const getAssetsByFamilyId = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/families/${id}/assets`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<IAsset[]>;
};
export const getAssetById = async ({
  clientId,
  id,
  token,
}: {
  clientId: string;
  id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${clientId}/assets/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<IAsset>;
};

export const postAsset = async ({
  clientId,
  data,
  token,
}: {
  clientId: string;
  data: IManualInput;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/clients/${clientId}/assets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<IAsset>;
};

export const putAsset = async ({
  clientId,
  id,
  data,
  token,
}: {
  clientId: string;
  id: string;
  data: IManualInput;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${clientId}/assets/${id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return response.json() as Promise<IAsset>;
};

export const deleteAsset = async ({
  client_id,
  id,
  token,
}: {
  client_id: string;
  id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/assets/${id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error((await response.json()).message);
  }
  return;
};


export const getClientAssetHistoryAggregationByAssetType = async ({id, token, freq, start_at, end_at}: {id: string, token: string, freq: "daily" | "weekly" | "monthly", start_at: string, end_at: string}) => {
  const response = await fetch(`${API_URL}/clients/${id}/assets/history/aggregation-by-asset-type?start_at=${start_at}&end_at=${end_at}&freq=${freq}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json() as Promise<ClientAssetHistoryAggregationByAssetType[]>;
}



export const getClientMentoringSheets = async ({id, token}: {id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${id}/mentoring-sheets`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error()
  }
  return response.json() as Promise<NewAPIMentoringSheetWithServerValues[]>;
}

export const getClientMentoringSheet = async ({client_id, mentoring_sheet_id, token}: {client_id: string, mentoring_sheet_id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<NewAPIMentoringSheetWithServerValues>;
}

export const postClientMentoringSheet = async (
  {client_id, data, token}: 
  {
    client_id: string,
     token: string, 
     data: PostClientMentoringSheetPayload
    }
  ) => {
  const response = await fetch(`${API_URL}/clients/${client_id}/mentoring-sheets/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<NewAPIMentoringSheetWithServerValues>;
}

export const putClientMentoringSheet = async ({client_id, id, data, token}: {client_id: string, id: string, token: string, data: NewAPIMentoringSheetStorePayload}) => {
  const response = await fetch(`${API_URL}/clients/${client_id}/mentoring-sheets/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<NewAPIMentoringSheetWithServerValues>;
}

export const deleteClientMentoringSheet = async ({client_id, id, token}: {client_id: string, id: string, token: string}) => {
  const response = await fetch(`${API_URL}/clients/${client_id}/mentoring-sheets/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return;
}
