import React from 'react';
import {
  Box,
  Stack,
  Link,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { useUpdatePassword } from './hooks';
import { PasswordTextField } from 'parts/PasswordTextField';
import { useAuth, } from 'hooks';
import { Loading } from 'components/Loading';
import { Header } from 'components/Header';
import { AdminHeader } from 'pages/RootLayout';

export const UpdatePasswordPage = () => {
  const { currentUser } = useAuth();
  const {
    password,
    newPassword,
    errorMessage,
    success,
    update,
    onPasswordChange,
    onNewPasswordChange,
    isLoading,
  } = useUpdatePassword();

  if (currentUser === undefined) {
    return (
      <>
        <Header title="" />
        <AdminHeader />
        <Loading />
      </>
    );
  }
  if (currentUser === null) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <>
      <Header title="" />
      <AdminHeader />
      <Box display="flex" justifyContent="center" sx={{ minHeight: '60vh' }}>
        <Stack
          spacing={2}
          sx={{ width: 330 }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h6" component="h2" sx={{ my: 8 }}>
            パスワードの変更
          </Typography>
          <PasswordTextField
            value={password}
            id="password"
            onChange={onPasswordChange}
            label="現在のパスワード"
            fullWidth
            variant="standard"
            autoComplete="current-password"
            sx={{ pb: 4 }}
          />
          <PasswordTextField
            value={newPassword}
            id="new-password"
            onChange={onNewPasswordChange}
            label="新しいパスワード"
            fullWidth
            error={errorMessage.length > 0}
            helperText={errorMessage}
            variant="standard"
            autoComplete="new-password"
            sx={{
              pb: 8,
              '& .MuiFormHelperText-root': {
                maxWidth: 'inherit',
              },
            }}
          />
          {/* change into navybutton */}
          {!success ? (
            <>
              <Button variant="contained" onClick={update} size="large">
                {isLoading ? (
                  <CircularProgress sx={{ color: 'white' }} />
                ) : (
                  '変更する'
                )}
              </Button>
              <Link
                component={RouterLink}
                to={'/' + currentUser.uid}
                replace={false}
                variant="body2"
              >
                今は変更しない
              </Link>
            </>
          ) : (
            <div>
              <Typography>パスワードが変更されました。</Typography>
              <Link href={'/' + currentUser.uid}>トップページに移動する</Link>
            </div>
          )}
        </Stack>
      </Box>
    </>
  );
};
