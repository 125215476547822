import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from 'contexts';
import { AdminSettingsPage } from 'pages/settings';
import { AdminSignInPage } from 'pages/SignIn';
import { InsightsPage } from 'pages/insights';
import { AdminClientLayout } from 'pages/clients/[clientId]/AdminClientLayout';
import { AdminClientDashboardPage } from 'pages/clients/[clientId]/adminDashboard';
import { AssetsPage } from 'pages/clients/[clientId]/assets';
import { AdminReportPage } from 'pages/clients/[clientId]/report';
import { AdminMentoringPage } from 'pages/clients/[clientId]/mentoring';
import { AdminClientsLayout } from 'pages/clients/AdminClientsLayout';
import { ClientsPage } from 'pages/clients';
import { RootPage } from 'pages';
import { UpdatePasswordPage } from 'pages/update-password';
import { SnackbarProvider } from 'components/Snackbar';
import { theme } from 'createTheme';
import { ResetPasswordPage } from 'pages/reset-password';
import { UpdateEmailPage } from 'pages/update-email';
import { AdminClientInsightPage } from 'pages/clients/[clientId]/adminClientInsight';
import { FamiliesPage } from 'pages/families';
import { AdminFamilyLayout } from 'pages/families/AdminFamilyLayout';
import { AdminFamilyReportPage } from 'pages/families/[:familyid]/AdminFamilyReportPage';
import { RootLayout } from 'pages/RootLayout';
import { AdminContentLayout } from 'pages/AdminContentLayout';
import { AppProvider } from 'contexts';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider>
          <AppProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/signin" element={<AdminSignInPage />} />
                <Route path="/" element={<RootLayout />}>
                  <Route index element={<RootPage />} />
                  <Route path="update-password" element={<UpdatePasswordPage />} />
                  <Route path="reset-password" element={<ResetPasswordPage />} />
                  <Route path="update-email" element={<UpdateEmailPage />} />
                  <Route path="/" element={<AdminContentLayout />}>
                    <Route path="insights" element={<InsightsPage />} />
                    <Route path="settings" element={<AdminSettingsPage />} />
                    <Route path="families" >
                      <Route index element={<FamiliesPage />} />
                      <Route path=":familyId" element={<AdminFamilyLayout />}>
                        <Route path="report" element={<AdminFamilyReportPage />} />
                      </Route>
                    </Route>
                    <Route path="clients" element={<AdminClientsLayout />}>
                      <Route index element={<ClientsPage />} />
                      <Route path=":clientId" element={<AdminClientLayout />}>
                        <Route index element={<AdminClientDashboardPage />} />
                        <Route path="mentoring" element={<AdminMentoringPage />} />
                        <Route
                          path="insight"
                          element={<AdminClientInsightPage />}
                        />
                        <Route path="assets" element={<AssetsPage />} />
                        <Route path="report" element={<AdminReportPage />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route path="*" element={<div>404</div>} />
              </Routes>
            </BrowserRouter>
          </AppProvider>
        </SnackbarProvider>
      </AuthProvider >
    </ThemeProvider >
  );
}
