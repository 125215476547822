import React from 'react';
import { Tab, Tabs, TabsProps } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';
import StarsIcon from '@mui/icons-material/Stars';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type AdminClientsTabsProps = TabsProps & {
  clientId: string;
  currentPath: string;
}

export const ClientMenuTabs = ({ clientId: newId, currentPath, ...restProps }: AdminClientsTabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const onChange = (event: React.SyntheticEvent, value: string) => {
    navigate(value);
  };

  const iconStyle = {
    fontSize: 'body1.fontSize',
    verticalAlign: 'bottom',
    mr: '3px',
  };

  let tabData: { path: string, name: string, icon: JSX.Element }[] = [];
  if (pathname.includes('clients')) {
    tabData = [
      {
        path: `/clients/${newId}`,
        name: 'ダッシュボード',
        icon: <DashboardIcon sx={iconStyle} />,
      },
      {
        path: `/clients/${newId}/mentoring`,
        name: 'G-POP\u{00AE}',
        icon: <EditRoundedIcon sx={iconStyle} />,
      },
      {
        path: `/clients/${newId}/insight`,
        name: '戦略くん',
        icon: <StarsIcon sx={iconStyle} />,
      },
      {
        path: `/clients/${newId}/assets`,
        name: '銘柄一覧',
        icon: <ListRoundedIcon sx={iconStyle} />,
      },
      {
        path: `/clients/${newId}/report`,
        name: 'レポート',
        icon: <ShowChartRoundedIcon sx={iconStyle} />,
      },
    ];
  } else if (pathname.includes('families')) {
    tabData = [
      {
        path: `/families/${newId}/report`,
        name: 'レポート',
        icon: <ShowChartRoundedIcon sx={iconStyle} />,
      },
    ];
  }


  // TODO: Remove trailing slash from URL
  const tabStyleForMobile = { fontSize: 11, mx: 0.5, minWidth: 0, p: 0 };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {isMobile ? (
        <Tabs
          value={currentPath}
          onChange={onChange}
          {...restProps}
          variant="fullWidth"
        >
          {tabData.map(tab => (
            <Tab
              key={tab.path}
              icon={tab.icon}
              value={tab.path}
              sx={tabStyleForMobile}
            />
          ))}
        </Tabs>
      ) : (
        <Tabs value={currentPath} onChange={onChange} {...restProps}>
          {tabData.map(tab => (
            <Tab
              key={tab.path}
              label={
                <div>
                  {tab.icon}
                  {tab.name}
                </div>
              }
              value={tab.path}
            />
          ))}
        </Tabs>
      )}
    </>
  );
};
