import { useEffect, useState } from 'react';
import {
  useToggle,
  useMember,
  useDownloadExcel
} from 'hooks';
import { ClientsMenu } from './ClientsMenu';
import { ClientsTable } from './ClientsTable';
import { AddClientDialog } from './AddClientDialog';
import { Loading, MainMenuTabs } from 'components';
import { NewApiClient, ClientForClientsPage, DraftClient } from 'types';
import { EditClientDialog } from './EditClientDialog';
import { Box } from '@mui/material';
import { DeleteClientDialog } from './DeleteClientDialog';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { replaceClientByIdAsync, selectClientError, selectClientMaxPortfoliohNumber, selectClients, selectClientsStatus, selectClientStatus, selectFamilies, selectFamiliesStatus, } from 'features';

export const ClientsPage = () => {
  const dispatch = useAppDispatch();
  const clients = useAppSelector(selectClients);
  const clientsStatus = useAppSelector(selectClientsStatus);
  const clientStatus = useAppSelector(selectClientStatus);
  const clientError = useAppSelector(selectClientError)
  const clientMaxPortfoliohNumber = useAppSelector(selectClientMaxPortfoliohNumber);
  const familiesStatus = useAppSelector(selectFamiliesStatus);
  const families = useAppSelector(selectFamilies);
  const [
    { members: mentors, status: memberStatus, error: memberError },
    { fetchedMembersByRole },
  ] = useMember();
  const [selectedClient, setSelectedClient] = useState<NewApiClient | null>(
    null
  );
  const [isOpenEditDialog, toggleIsOpenEditDialog] = useToggle(false);
  const [isOpenCreateDialog, toggleIsOpenCreateDialog] = useToggle(false);
  const [isOpenDeleteDialog, toggleIsOpenDeleteDialog] = useToggle(false);
  const [downloadExcelState, { downloadExcelForAllClients }] =
    useDownloadExcel();
  const [searchClientQuery, setSearchClientQuery] = useState('');
  const handleSearchClientChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchClientQuery(event.target.value);
  };

  const clientWithFamilyNameMentorName: ClientForClientsPage[] = (clients ?? []).map(
    (client: NewApiClient): ClientForClientsPage => {
      let familyName = '';
      if (client.familyId) {
        switch(familiesStatus) {
          case 'pending':
            familyName = 'Loading...';
            break;
          case 'rejected':
            familyName = '読み込みに失敗しました'
            break;
          case 'succeeded':
            familyName = families.find(family => family.id === client.familyId)?.name ?? '';
            break;
          default:
            break;
        }
      }

      return ({
        ...client,
        familyName,
        mentorName:
          mentors.find(mentor => mentor.id === client.mentoredBy)?.fullName ||
          '',
      })
    }
  );

  useEffect(() => {
    if (memberStatus === 'idle') {
      fetchedMembersByRole('mentor');
    }
  }, [fetchedMembersByRole, memberStatus]);

  if (
    clientsStatus === 'pending'
  ) {
    return <Loading />;
  }

  const handleEdit = async (formState: DraftClient) => {
    if (selectedClient) {
      try {
        dispatch(replaceClientByIdAsync({ id: selectedClient.id, data: formState }));
      } catch (e) {
        console.log('Error updating client:', e);
        throw e;
      }
    } else {
      console.log('No client selected.');
    }
  };

  const handleCloseCreateDialog = () => {
    setSelectedClient(null);
    toggleIsOpenCreateDialog();
  };

  const handleCloseEditDialog = () => {
    setSelectedClient(null);
    toggleIsOpenEditDialog();
  };

  const handleCloseDeleteDialog = () => {
    setSelectedClient(null);
    toggleIsOpenDeleteDialog();
  };

  return (
    <>
      <MainMenuTabs tabValue='/clients' />

      <Box sx={{ px: 4, py: 1 }}>
        <ClientsMenu
          downloadExcel={downloadExcelForAllClients}
          toggleAddClientDialog={toggleIsOpenCreateDialog}
          toggleEditClientDialog={toggleIsOpenEditDialog}
          toggleDeleteClientDialog={toggleIsOpenDeleteDialog}
          isClientSelected={selectedClient !== null}
          downloadExcelStatus={downloadExcelState.status}
          downloadExcelErrorMessage={downloadExcelState.errorMessage}
          clientSearchQuery={searchClientQuery}
          onChangeClientSearchQuery={handleSearchClientChange}
        />
        <ClientsTable
          clients={clientWithFamilyNameMentorName.filter(
            client =>
              client.name
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
              (client.familyName ?? '')
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
              (client.note ?? '')
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
              (client.clientBillingId ?? '')
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase()) ||
              (client.mentorName ?? '')
                .toLocaleLowerCase()
                .includes(searchClientQuery.trim().toLocaleLowerCase())
          )}
          families={families}
          mentors={mentors}
          selectedClient={selectedClient}
          onSelectionChangeRequest={target => {
            setSelectedClient(current =>
              !current || current.id !== target.id ? target : null
            );
          }}
        />
        {families !== undefined && (
          <AddClientDialog
            key={clientMaxPortfoliohNumber}
            open={isOpenCreateDialog}
            onClose={handleCloseCreateDialog}
            familyOptions={families}
            maxPortpholiohIdNumber={clientMaxPortfoliohNumber ?? 0}
          />
        )}
        {selectedClient && families !== undefined && (
          <EditClientDialog
            open={isOpenEditDialog}
            onClose={handleCloseEditDialog}
            client={selectedClient}
            families={families}
            mentors={mentors}
            onSave={handleEdit}
            status={clientStatus}
            error={clientError}
            key={selectedClient.id}
          />
        )}
        {selectedClient && (
          <DeleteClientDialog
            open={isOpenDeleteDialog}
            onClose={handleCloseDeleteDialog}
            client={selectedClient}
          />
        )}
      </Box>
    </>
  );
};
