import { Family, FamilyAssetHistoryAggregationByAssetType, FinancialSummary } from "types";

const API_URL = process.env.REACT_APP_PORTFOLIOH_API_URL as string;
export const getFamilies = async (token: string) => {
  const response = await fetch(`${API_URL}/families`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<Family[]>;
};

export const getFamilyById = async ({
  id,
  token,
}: {
  id: string;
  token: string;
}) => {
  const response = await fetch(`${API_URL}/families/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<Family>;
};

export const postFamily = async ({
  data,
  token,
}: {
  data: { name: string };
  token: string;
}) => {
  const response = await fetch(`${API_URL}/families`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<Family>;
};

export const putFamilyById = async ({
  id,
  data,
  token,
}: {
  id: string;
  data: { name: string };
  token: string;
}) => {
  const response = await fetch(`${API_URL}/families/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error((await response.json()).message)
  }

  return response.json() as Promise<Family>;
};

export const getFamilyFinancialSummary = async ({id, token}: {id: string, token: string}) => {
  const response = await fetch(`${API_URL}/families/${id}/assets/summary`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<FinancialSummary>;
}



export const getFamilyAssetHistoryAggregationByAssetType = async ({id, token, freq, start_at, end_at}: {id: string, token: string, freq: "daily" | "weekly" | "monthly", start_at: string, end_at: string}) => {
  const response = await fetch(`${API_URL}/families/${id}/assets/history/aggregation-by-asset-type?start_at=${start_at}&end_at=${end_at}&freq=${freq}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error((await response.json()).message)
  }
  return response.json() as Promise<FamilyAssetHistoryAggregationByAssetType[]>;
}
