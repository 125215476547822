import React, { useState } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormHelperText,
  TextFieldProps,
  styled,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

interface IField {
  value: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}
interface ISelect extends IField {
  onChange?: (event: SelectChangeEvent) => void;
}

export const AssetDialogFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
}));

interface ITextField extends IField {
  label: string;
  placeholder?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

const BrokerageAccountField = ({
  value,
  onChange,
  error,
  helperText,
}: ISelect) => {
  return (
    <AssetDialogFormControl error={error}>
      <InputLabel id="brokerage-account-select-label">証券口座*</InputLabel>
      <Select
        labelId="brokerage-account-select-label"
        value={value}
        displayEmpty
        onChange={onChange}
        inputProps={{ style: { textAlign: 'left' } }}
        label="証券口座*"
      >
        {[
          '楽天証券',
          'SBI',
          '三菱MS(WM)',
          '三菱MS',
          '日興証券',
          '大和証券',
          '野村証券',
          'みずほ証券',
          'CS',
          'UBS',
          '東海東京証券',
          '三菱UFJ銀行',
          'みずほ銀行',
          '三井住友銀行',
          'りそな銀行',
          'マネックス証券',
          'あかつき証券',
          'HiJoJo Partners',
          'ジュリアス・ベア',
          'その他',
        ].map(brok => (
          <MenuItem key={brok} value={brok}>
            {brok}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </AssetDialogFormControl>
  );
};

const AccountTypeField = ({ value, onChange, error, helperText }: ISelect) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleOpen = () => {
    setIsFocused(true);
  };
  const handleClose = () => {
    setIsFocused(false);
  };

  return (
    <AssetDialogFormControl error={error}>
      <InputLabel
        id="account-type-select-label"
        shrink={value !== '' || isFocused}
      >
        口座区分
      </InputLabel>
      <Select
        labelId="account-type-select-label"
        value={value}
        displayEmpty
        onChange={onChange}
        onFocus={handleOpen}
        onBlur={handleClose}
        inputProps={{ style: { textAlign: 'left' } }}
        label="口座区分"
      >
        <MenuItem value={null as any}>指定なし</MenuItem>
        <MenuItem value="旧NISA">旧NISA</MenuItem>
        <MenuItem value="NISA積立">NISA積立</MenuItem>
        <MenuItem value="NISA成長">NISA成長</MenuItem>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </AssetDialogFormControl>
  );
};

const ProductTypeField = ({
  value,
  onChange,
  disabled = false,
  error = false,
  helperText = '',
}: ISelect) => {
  return (
    <AssetDialogFormControl error={error}>
      <InputLabel id="product-type-select-label">資産クラス*</InputLabel>
      <Select
        // labelId="product-type-select-label"
        // id="product-type-select"
        disabled={disabled}
        value={value}
        onChange={onChange}
        label="資産クラス*"
      >
        <MenuItem value="債券">債券</MenuItem>
        <MenuItem value="株式">株式</MenuItem>
        <MenuItem value="ETF">ETF</MenuItem>
        <MenuItem value="投資信託">投資信託</MenuItem>
        <MenuItem value="ファンド">ファンド</MenuItem>
        <MenuItem value="キャッシュ">キャッシュ</MenuItem>
        <MenuItem value="不動産">不動産</MenuItem>
        <MenuItem value="オルタナティブ">オルタナティブ</MenuItem>
        <MenuItem value="非上場株式">非上場株式</MenuItem>
        <MenuItem value="不動産">不動産</MenuItem>
        <MenuItem value="その他">その他</MenuItem>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </AssetDialogFormControl>
  );
};

const ProductTypeAltField = ({
  value,
  onChange,
  disabled = false,
  error = false,
  helperText = '',
}: ISelect) => {
  return (
    <AssetDialogFormControl error={error}>
      <InputLabel id="product-type-select-label">資産クラス*</InputLabel>
      <Select
        disabled={disabled}
        value={value}
        onChange={onChange}
        label="資産クラス*"
      >
        <MenuItem value="オルタナティブ">オルタナティブ</MenuItem>
        <MenuItem value="非上場株式">非上場株式</MenuItem>
        <MenuItem value="不動産">不動産</MenuItem>
        <MenuItem value="その他">その他</MenuItem>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </AssetDialogFormControl>
  );
};

const CurrencyField = ({
  value,
  onChange,
  disabled = false,
  error = false,
  helperText = '',
}: ISelect) => {
  return (
    <AssetDialogFormControl error={error}>
      <InputLabel id="currency-select-label">通貨*</InputLabel>
      <Select
        labelId="currency-select-label"
        // id="currency-select"
        disabled={disabled}
        value={value}
        onChange={onChange}
        label="通貨*"
      >
        <MenuItem value="JPY">JPY</MenuItem>
        <MenuItem value="USD">USD</MenuItem>
        <MenuItem value="EUR">EUR</MenuItem>
        <MenuItem value="GBP">GBP</MenuItem>
        <MenuItem value="AUD">AUD</MenuItem>
        <MenuItem value="NZD">NZD</MenuItem>
        <MenuItem value="BRL">BRL</MenuItem>
        <MenuItem value="MXN">MXN</MenuItem>
        <MenuItem value="TRY">TRY</MenuItem>
        <MenuItem value="RUB">RUB</MenuItem>
        <MenuItem value="CNH">CNH</MenuItem>
        <MenuItem value="CNY">CNY</MenuItem>
        <MenuItem value="ZAR">ZAR</MenuItem>
        <MenuItem value="IND">IND</MenuItem>
        <MenuItem value="IDR">IDR</MenuItem>
        <MenuItem value="CHF">CHF</MenuItem>
        <MenuItem value="CAD">CAD</MenuItem>
        <MenuItem value="HKD">HKD</MenuItem>
        <MenuItem value="ARS">ARS</MenuItem>
        <MenuItem value="SGD">SGD</MenuItem>
        <MenuItem value="THB">THB</MenuItem>
        <MenuItem value="TWD">TWD</MenuItem>
        <MenuItem value="LRW">LRW</MenuItem>
        <MenuItem value="NOK">NOK</MenuItem>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </AssetDialogFormControl>
  );
};

const UnitField = ({
  value,
  onChange,
  disabled = false,
  error = false,
  helperText = '',
}: ISelect) => {
  return (
    <AssetDialogFormControl error={error}>
      <InputLabel id="unit-select-label">単位*</InputLabel>
      <Select
        labelId="unit-select-label"
        // id="unit-select"
        disabled={disabled}
        value={value}
        onChange={onChange}
        label="単位*"
      >
        <MenuItem value="額面">額面</MenuItem>
        <MenuItem value="口">口</MenuItem>
        <MenuItem value="株">株</MenuItem>
        <MenuItem value="円">円</MenuItem>
        <MenuItem value="外貨">外貨</MenuItem>
        <MenuItem value="単位なし">単位なし</MenuItem>
        <MenuItem value="その他">その他</MenuItem>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </AssetDialogFormControl>
  );
};

const CacheUnitField = ({
  value,
  onChange,
  disabled = false,
  error = false,
  helperText = '',
}: ISelect) => {
  return (
    <AssetDialogFormControl error={error}>
      <InputLabel id="unit-select-label">単位*</InputLabel>
      <Select
        labelId="unit-select-label"
        // id="unit-select"
        disabled={disabled}
        value={value}
        onChange={onChange}
        label="単位*"
      >
        <MenuItem value="円">円</MenuItem>
        <MenuItem value="外貨">外貨</MenuItem>
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </AssetDialogFormControl>
  );
};

const NumericalTextField = ({
  value,
  onChange,
  label,
  error = false,
  helperText = '',
  onBlur,
  onFocus,
  inputRef,
}: TextFieldProps) => {
  return (
    <TextField
      type="text"
      inputProps={{ style: { textAlign: 'right' } }}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={error}
      helperText={helperText}
      inputRef={inputRef}
      fullWidth
      autoComplete="off"
    />
  );
};

const StringTextField = ({
  value,
  onChange,
  label,
  disabled = false,
  placeholder = '',
  helperText = '',
  error = false,
}: ITextField) => {
  return (
    <TextField
      type="text"
      label={label}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      helperText={helperText}
      error={error}
      fullWidth
      autoComplete="off"
    />
  );
};

export {
  BrokerageAccountField,
  AccountTypeField,
  ProductTypeField,
  ProductTypeAltField,
  CurrencyField,
  UnitField,
  NumericalTextField,
  StringTextField,
  CacheUnitField,
};
