import { useAppDispatch } from "app/hooks";
import { Header, Loading } from "components";
import { CustomAppBar } from "components/CustomAppBar";
import { clientSlice, familySlice, fetchClientsAsync, fetchFamiliesAsync, fetchFinancialProductsAsync, financialProductSlice } from "features";
import { useAuth } from "hooks";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

export const AdminHeader = () => (
  <CustomAppBar title="管理画面" topHref="/clients" />
);

export const RootLayout = () => {
  const { currentUser } = useAuth();
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (currentUser) {
      dispatch(fetchFamiliesAsync());
      dispatch(fetchClientsAsync())
      dispatch(fetchFinancialProductsAsync());
    }
    return () => {
      dispatch(clientSlice.actions.reset());
      dispatch(familySlice.actions.reset());
      dispatch(financialProductSlice.actions.reset());
    }
  }, [dispatch, currentUser]);
  if (currentUser === undefined) {
    return (
      <>
        <Header title="" />
        <Loading />
      </>
    );
  }
  if (currentUser === null) {
    return (
      <Navigate to="/signin" replace />
    );
  }
  return (
    <>
      <Header title="" />
      <Outlet />
    </>

  );
}