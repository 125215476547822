import React from 'react';

import {
  Box,
  TextField,
  Button,
  CircularProgress,
  TextFieldProps,
} from '@mui/material';

interface IAuthTextField {
  label?: string;
  value: string;
  handleChange: (value: string) => void;
  errorMessage: string;
}
export const AuthTextField = (props: IAuthTextField & TextFieldProps) => {
  const { label, value, handleChange, errorMessage, type } = props;
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };
  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        {...props}
        onChange={onChange}
        variant="outlined"
        value={value}
        size="small"
        placeholder={label}
        fullWidth
        helperText={errorMessage}
        error={errorMessage.length > 0}
        type={type}
      />
    </Box>
  );
};
