import { TableCell, styled, Checkbox, TableCellProps } from '@mui/material';
import { MouseEventHandler } from 'react';

export const FrozenColumnTableCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  zIndex: theme.zIndex.appBar + 1,
  left: 0,
  whiteSpace: 'unset',
  backgroundColor: '#fafafa',
}));

interface FrozenColumnTableCheckBoxCellProps extends TableCellProps {
  checked: boolean;
  // onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickCheckbox: MouseEventHandler<HTMLButtonElement>;
}

const FrozenColumnTableCheckBoxCell: React.FC<
  FrozenColumnTableCheckBoxCellProps
> = ({ children, checked, onClickCheckbox, ...props }) => (
  <FrozenColumnTableCell
    sx={{ display: 'flex', alignItems: 'center', pl: 0.3, pr: 0.6, py: 0.2 }}
  >
    <Checkbox
      sx={{ display: 'block' }}
      color="secondary"
      checked={checked}
      tabIndex={-1}
      disableRipple
      // inputProps={{ "aria-labelledby": labelId }}
      onClick={onClickCheckbox}
      size="small"
    />
    <div>{children}</div>
  </FrozenColumnTableCell>
);

interface AssetFirstColumnProps {
  children: React.ReactNode;
  checked?: boolean;
  onClickCheckbox?: MouseEventHandler<HTMLButtonElement>;
  checkboxSelection: boolean;
}

export const AssetFirstColumn: React.FC<AssetFirstColumnProps> = ({
  children,
  checkboxSelection,
  checked,
  onClickCheckbox,
}) =>
  checkboxSelection && onClickCheckbox && checked !== undefined ? (
    <FrozenColumnTableCheckBoxCell
      onClickCheckbox={onClickCheckbox}
      checked={checked}
    >
      {children}
    </FrozenColumnTableCheckBoxCell>
  ) : (
    <FrozenColumnTableCell>{children}</FrozenColumnTableCell>
  );
