import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Divider,
} from '@mui/material';
import { NewApiClient, Family, } from 'types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

type SelectAccountProps = {
  currentId: string,
  clients: NewApiClient[],
  family: Family,
}

export const SelectClient = ({
  currentId,
  clients,
  family,
}: SelectAccountProps) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const onClick = (action: { id: string, type: 'family' | 'client' }) => {
    const nextPath = pathReducer({ path: currentPath }, action)
    navigate(nextPath.path);
  }
  const showFamily = currentPath.includes('report')
  return (
    <FormControl sx={{ minWidth: 120, ml: { xs: 1, sm: 2 } }} size="small" >
      <Select
        value={currentId}
        onChange={(event: SelectChangeEvent) =>
          onClick({
            id: event.target.value,
            type: family.id === event.target.value ? 'family' : 'client',
          })
        }
        autoWidth
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: 14, sm: 16 },
          '& .MuiSelect-select': {
            '&::after': {
              content: '"様"',
              marginLeft: "0.25em",
            },
          },
        }}
      >
        {clients.map(c => (
          <MenuItem
            key={c.id}
            value={c.id}
            sx={{
              '&::after': {
                content: '"様"',
                marginLeft: "0.25em",
              },
            }}
          >{c.name}</MenuItem>
        ))}
        {showFamily && ([

          <Divider key="divider" />,
          <MenuItem
            key={family.id}
            value={family.id}
          >{family.name}</MenuItem>
        ])}
      </Select>
    </FormControl >
  )
};

// Reducerの初期状態
interface State {
  path: string;
}

const initialState: State = {
  path: '/clients',
};

// Actionの型
interface UpdatePathAction {
  type: 'client' | 'family';
  id: string;
}

// Helper関数: 現在のパスに基づいて遷移先のパスを決定
const generateNewPath = (currentPath: string, type: 'client' | 'family', newId: string): string => {
  const pathParts = currentPath.split('/'); // 現在のパスを分割
  switch (type) {
    case 'client': {
      if (pathParts.includes('assets')) {
        return `/clients/${newId}/assets`;
      } else if (pathParts.includes('report')) {
        return `/clients/${newId}/report`;
      } else if (pathParts.includes('/mentoring-sheets')) {
        return `/clients/${newId}/mentoring-sheets`;
      } else {
        return `/clients/${newId}/`;
      }
    }
    case 'family': {
      if (pathParts.includes('assets')) {
        return `/families/${newId}/assets`;
      } else if (pathParts.includes('report')) {
        return `/families/${newId}/report`;
      } else {
        throw new Error('Unexpected path. currentpath: ' + currentPath + " type: " + type + " newId: " + newId);
      }
    }
    default: {
      const _never: never = type;
      throw new Error('Unexpected type: ' + _never);
    }
  }
};

// Reducer
function pathReducer(state: State = initialState, action: UpdatePathAction): State {
  switch (action.type) {
    case 'client':
      return {
        path: generateNewPath(state.path, action.type, action.id),
      };

    case 'family':
      if (state.path.includes('assets') || state.path.includes('report')) {
        return {
          path: generateNewPath(state.path, action.type, action.id),
        };
      }
      throw new Error('Unexpected path: ' + state.path + ' ' + action.type + ' ' + action.id);
    default:
      const _never: never = action.type;
      return state;
  }
};
