import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

type DeleteMentoringSheetDialogProps = {
  open: boolean;
  deleteSheet: () => void;
  onClose: () => void;
};
export const DeleteMentoringSheetDialog: React.FC<
  DeleteMentoringSheetDialogProps
> = ({ open, deleteSheet, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>G-POP&reg;シート削除</DialogTitle>
      <DialogContent>
        <DialogContentText>本当に削除しますか。</DialogContentText>
        <DialogContentText variant="caption">
          この操作は元に戻せません。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            try {
              deleteSheet();
              onClose();
            } catch (e) {
              throw e;
            }
          }}
        >
          削除
        </Button>
      </DialogActions>
    </Dialog>
  );
};
