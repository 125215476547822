import {createSelector, createSlice, PayloadAction,} from '@reduxjs/toolkit'
import { ClientAssetHistoryAggregationByAssetType, DraftClient, FinancialSummary, IAsset, IManualInput, NewApiClient, NewAPIMentoringSheet, NewAPIMentoringSheetWithServerValues, PostClientMentoringSheetPayload, WithId, WithTimestamp } from 'types'
import type {RootState} from '../../app/store'
import { createAppAsyncThunk } from '../../app/withTypes'
import { getToken } from 'features/auth'
import { addClient, deleteAsset, deleteClientMentoringSheet, getAssetsByClientId, getClientAssetHistoryAggregationByAssetType, getClientById, getClientFinancialSummary, getClientMentoringSheets as getClientMentoringSheetsByClientId, getClients, postAsset, postClientMentoringSheet, putAsset, putClientById, putClientMentoringSheet } from 'api'


type ClientsState = {
  clients: NewApiClient[],
  clientsStatus: 'idle' | 'pending' | 'succeeded' | 'rejected',
  client: NewApiClient | null,
  status: 'idle' | 'pending' | 'succeeded' | 'rejected',
  error: string | null,
  assets: IAsset[],
  asset: IAsset|null,
  summary: FinancialSummary | null
  maxPortpholiohIdNumber: number|null
  historicalAggregationByAssetTypeMonthly: ClientAssetHistoryAggregationByAssetType[]
  mentoringSheets: WithTimestamp<WithId<NewAPIMentoringSheet>>[],
  mentoringSheet: WithTimestamp<WithId<NewAPIMentoringSheet>> | null
}

const initialState: ClientsState = {
  clients: [],
  clientsStatus: 'idle',
  client: null,
  status: 'idle',
  error: null,
  assets: [],
  asset: null,
  summary: null,
  maxPortpholiohIdNumber: null,
  historicalAggregationByAssetTypeMonthly: [],
  mentoringSheets: [],
  mentoringSheet: null,
}


export const fetchClientsAsync = createAppAsyncThunk(
  'client/fetchClients',
  async (params?: {family_id: string}) =>  {
    const token = await getToken();
    const response = await getClients({token, familyId:params?.family_id});
    return response;
  }
)

export const fetchClientByIdAsync = createAppAsyncThunk(
  'client/fetchClientById',
  async ({id}: {id: string}) =>  {
    const token = await getToken();
    const twoYearsAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 2)
    ).toISOString();
    const today = new Date().toISOString();
    const [client, assets, summary, historicalAggregationByAssetType, mentoringSheets] = await Promise.all([
      getClientById({id, token}),
      getAssetsByClientId({clientId: id, token}),
      getClientFinancialSummary({id, token}),
      getClientAssetHistoryAggregationByAssetType({
        id: id,
        token: token,
        freq: 'monthly',
        start_at: twoYearsAgo,
        end_at: today,
      }),
      getClientMentoringSheetsByClientId({id, token}),
    ])
    return {client, assets, summary, historicalAggregationByAssetType, mentoringSheets};
  }
)

// TODO: Create an API endpoint to create a new client
export const createClientAsync = createAppAsyncThunk(
  'client/createClient',
  async ({data}: {data: {
    name: string;
    email: string;
    password: string;
    familyId?: string;
    familyName?: string;
    status: string;
    clientBillingId: string;
  }}) =>  {
    const response = await addClient(data);
    const token = await getToken();
    await getClients({token});

  }
)

export const replaceClientByIdAsync = createAppAsyncThunk(
  'client/replaceClientById',
  async ({id, data}: {id: string, data: DraftClient}) =>  {
    const token = await getToken();
    const response = await putClientById({id, data, token});
    return response;
  }
)

export const fetchAssetsByClientIdAsync = createAppAsyncThunk(
  'client/fetchAssetsByClientId',
  async ({id}: {id: string}) =>  {
    const token = await getToken();
    const response = await getAssetsByClientId({clientId: id, token});
    return response;
  }
)

export const createAssetAsync = createAppAsyncThunk(
  'client/createAsset',
  async ({client_id, data}: {client_id: string, data: IManualInput}) =>  {
    const token = await getToken();
    const newAsset = await postAsset({ clientId: client_id, data, token });
    return newAsset;    
  }
)

export const replaceAssetAsync = createAppAsyncThunk(
  'client/replaceAsset',
  async ({client_id, id, data}: {client_id: string, id: string, data: IManualInput}) =>  {
    const token = await getToken();
    const response = await putAsset({clientId: client_id, id, data, token});
    return response;
  }
)

export const removeAssetAsync = createAppAsyncThunk(
  'client/deleteAsset',
  async ({client_id, id}: {client_id: string, id: string}) =>  {
    const token = await getToken();
    const response = await deleteAsset({client_id: client_id, id, token});
    return response;
  }
)

export const fetchMentoringSheetsByClientIdAsync = createAppAsyncThunk(
  'client/fetchMentoringSheets',
  async ({id}: {id: string}) =>  {
    const token = await getToken();
    const response = await getClientMentoringSheetsByClientId({id, token});
    return response;
  }
)

export const createMentoringSheetAsync = createAppAsyncThunk(
  'client/createMentoringSheet',
  async ({client_id, data}: {client_id: string, data: PostClientMentoringSheetPayload}) =>  {
    const token = await getToken();
    const response = await postClientMentoringSheet({client_id, data, token});
    return response;
  }
)

export const replaceMentoringSheetAsync = createAppAsyncThunk(
  'client/replaceMentoringSheet',
  async ({client_id, id, data}: {client_id: string, id: string, data: NewAPIMentoringSheet}) =>  {
    const token = await getToken();
    const response = await putClientMentoringSheet({client_id, id, data, token});
    return response;
  }
)

export const removeMentoringSheetAsync = createAppAsyncThunk(
  'client/deleteMentoringSheet',
  async ({client_id, id}: {client_id: string, id: string}) =>  {
    const token = await getToken();
    await deleteClientMentoringSheet({client_id, id, token});
    return;
  }
)

export const clientSlice = createSlice({
  name: 'client',
  initialState: initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    deselectClient(state) {
      return {
        ...initialState,
        clients: state.clients,
      }
    },
    dismissError(state) {
      state.error = null
      state.status = 'succeeded'
    },
    selectMentoringSheet(state, action: PayloadAction<NewAPIMentoringSheetWithServerValues>) {
      state.mentoringSheet = action.payload
    },
    selectAsset(state, action: PayloadAction<IAsset>) {
      state.asset = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchClientsAsync.pending, (state, action) => {
        state.clientsStatus= 'pending'
      })
      .addCase(fetchClientsAsync.fulfilled, (state, action) => {
        state.clientsStatus= 'succeeded'
        state.clients = action.payload
        const numbers = (action.payload ?? [{ name: '0' }]).map(client => {
          const match = client.name.match(/^P?(\d{4})$/);
          return match ? parseInt(match[1], 10) : 0;
        });
        state.maxPortpholiohIdNumber = Math.max(...numbers);
      })
      .addCase(fetchClientsAsync.rejected, (state, action) => {
        state.clientsStatus= 'rejected'
        state.error = action.error.message ?? 'エラーが発生しました'
      })
      .addCase(fetchClientByIdAsync.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(fetchClientByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.client = action.payload.client
        state.assets = action.payload.assets
        state.summary = action.payload.summary
        state.historicalAggregationByAssetTypeMonthly = action.payload.historicalAggregationByAssetType
        state.mentoringSheets = action.payload.mentoringSheets
        state.mentoringSheet = action.payload.mentoringSheets[0] ?? null
      })
      .addCase(fetchClientByIdAsync.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message ?? 'エラーが発生しました'
      })
      .addCase(createClientAsync.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(createClientAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(replaceClientByIdAsync.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(replaceClientByIdAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const id = action.meta.arg.id
        const idx = state.clients.findIndex(client => client.id === id)
        const clients = [
          ...state.clients.slice(0, idx),
          action.payload,
          ...state.clients.slice(idx + 1),
        ]
        state.clients = clients;
        const numbers = (clients ?? [{ name: '0' }]).map(client => {
          const match = client.name.match(/^P?(\d{4})$/);
          return match ? parseInt(match[1], 10) : 0;
        });
        state.maxPortpholiohIdNumber = Math.max(...numbers);
      })
      .addCase(replaceClientByIdAsync.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message ?? 'エラーが発生しました'
      })
      .addCase(createAssetAsync.pending, (state, action) => {
        state.status = 'pending'        
      })
      .addCase(createAssetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.assets = [action.payload, ...state.assets]
      })
      .addCase(createAssetAsync.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message ?? 'エラーが発生しました'
      })
      .addCase(replaceAssetAsync.pending, (state, action) => {
        state.status = 'pending'        
      })
      .addCase(replaceAssetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const idx = state.assets.findIndex(asset => asset.id === action.meta.arg.id)
        state.assets = [
          ...state.assets.slice(0, idx),
          action.payload,
          ...state.assets.slice(idx + 1),
        ]
      })
      .addCase(replaceAssetAsync.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message ?? 'エラーが発生しました'
      })
      .addCase(removeAssetAsync.pending, (state, action) => {
        state.status = 'pending'        
      })
      .addCase(removeAssetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.assets = state.assets.filter(asset => asset.id !== action.meta.arg.id)
      })
      .addCase(removeAssetAsync.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message ?? 'エラーが発生しました'
      })
      .addCase(createMentoringSheetAsync.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(createMentoringSheetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.mentoringSheets = [action.payload, ...state.mentoringSheets, ]
        state.mentoringSheet = action.payload;        
      })
      .addCase(createMentoringSheetAsync.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message ?? 'エラーが発生しました'
      })
      .addCase(replaceMentoringSheetAsync.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(replaceMentoringSheetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const idx = state.mentoringSheets.findIndex(mentoringSheet => mentoringSheet.id === action.meta.arg.id)
        state.mentoringSheets = [
          ...state.mentoringSheets.slice(0, idx),
          action.payload,
          ...state.mentoringSheets.slice(idx + 1),
        ]
        state.mentoringSheet = action.payload;
      })
      .addCase(replaceMentoringSheetAsync.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message ?? 'エラーが発生しました'
      })
      .addCase(removeMentoringSheetAsync.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(removeMentoringSheetAsync.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.mentoringSheets = state.mentoringSheets.filter(mentoringSheet => mentoringSheet.id !== action.meta.arg.id)
        state.mentoringSheet = state.mentoringSheets[0] ?? null
      })
      .addCase(removeMentoringSheetAsync.rejected, (state, action) => {
        state.status = 'rejected'
        state.error = action.error.message ?? 'エラーが発生しました'
      })
  }
})

export const selectClientsStatus = (state: RootState) => state.client.clientsStatus;
export const selectClients = (state: RootState) => state.client.clients

export const selectFamilyClients = createSelector([
  (state: RootState) => state.client.clients,
  (state: RootState, family_id: string) => family_id,
], 
  (clients: NewApiClient[], family_id: string) => clients.filter(client => client.familyId === family_id)
);

export const selectClientStatus = (state: RootState) => state.client.status
export const selectClient = (state: RootState) => state.client.client;
export const selectClientAssets = (state: RootState) => state.client.assets;
export const selectClientAsset = (state: RootState) => state.client.asset;
export const selectClientError = (state: RootState) => state.client.error;
export const selectClientFinancialSummary = (state: RootState) => state.client.summary;
export const selectClientMaxPortfoliohNumber = (state: RootState) => state.client.maxPortpholiohIdNumber;
export const selectClienttHistoricalAggregationByAssetTypeMonthly = (state: RootState) => state.client.historicalAggregationByAssetTypeMonthly;
export const selectClientMentoringSheets = (state: RootState) => state.client.mentoringSheets;
export const selectClientMentoringSheet = (state: RootState) => state.client.mentoringSheet;
export const selectClientLatestMentoringSheet = (state: RootState) => state.client.mentoringSheets[0] ?? null;
