import { useEffect } from 'react';
import { Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { SelectClient, Loading, ClientMenuTabs } from 'components';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { familySlice, fetchFamilyByIdAsync, selectClient, selectClientStatus, selectFamily, selectFamilyClients, selectFamilyStatus } from 'features';


export const AdminFamilyLayout = () => {
  const { familyId } = useParams() as { familyId: string };
  const dispatch = useAppDispatch();
  const family = useAppSelector(selectFamily);
  const familyStatus = useAppSelector(selectFamilyStatus);
  const familyClients = useAppSelector(state => selectFamilyClients(state, familyId));  // TODO: handle null family properly

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchFamilyByIdAsync({ id: familyId }));
    return () => {
      dispatch(familySlice.actions.deselectFamily())
    }
  }, [dispatch, familyId]);

  if (familyStatus === 'idle' || familyStatus === 'pending' || family === null) {
    return <Loading />;
  }

  return (
    <>
      <Box
        sx={{ minWidth: 120, borderBottom: 'solid 1px #ddd' }}
        display="flex"
        alignItems="center"
      >
        <IconButton onClick={() => {
          navigate('/clients');
        }}>
          <ArrowBackRoundedIcon sx={{ verticalAlign: 'bottom', ml: '2px' }} />
        </IconButton>
        <SelectClient
          clients={familyClients}
          family={family}
          currentId={family.id}
        />
        <ClientMenuTabs
          sx={{ mx: 2 }}
          clientId={family.id}
          currentPath={pathname}
        />
      </Box >
      <Outlet />
    </>
  );
};
