import React, { useState } from 'react';
import {
  AppBar,
  Typography,
  Toolbar,
  Box,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Link,
  Divider,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from 'hooks';

export interface ICustomAppBar {
  title: string;
  topHref: string;
}

export const CustomAppBar = React.memo(({ title, topHref }: ICustomAppBar) => (
  <AppBar position="static" elevation={0}>
    <Toolbar variant="dense">
      <Box sx={{ display: 'flex', alignItems: 'center' }} flexGrow={1}>
        <Link component={RouterLink} to={topHref}>
          <img
            width="20px"
            src={process.env.PUBLIC_URL + '/logo.svg'}
            style={{ marginRight: '10px', verticalAlign: 'bottom' }}
          />
        </Link>
        <Typography style={{ fontSize: '16px' }}>
          ポートフォリ王&reg;　{title}
        </Typography>
      </Box>
      <AccountMenu />
    </Toolbar>
  </AppBar>
));

const AccountMenu = () => {
  const { signOut } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => setAnchorElUser(null);
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="ポートフォリ王&reg; アカウントメニュー">
        <IconButton onClick={handleOpenUserMenu} size="small">
          <AccountCircle sx={{ color: 'white' }} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="user-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key="update-email" component={Link} href="/update-email">
          <ListItemText>メールアドレスの変更</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          key="update-password"
          component={Link}
          href="/update-password"
        >
          <ListItemText>パスワードの変更</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          key="help"
          component={Link}
          href="https://knowledge.pragmaworks.co.jp"
        >
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText>ヘルプ</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem key="signOut" onClick={signOut}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>ログアウト</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};
