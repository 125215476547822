import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { IAsset } from 'types/asset';

export type TableFilterOption = {
  name: string;
  field: '証券口座' | '資産クラス' | '通貨';
};
interface AssetFilterProps {
  allRows: IAsset[];
  setTableFilterOptions: (options: TableFilterOption[]) => void;
}

const compareFnBrokerageAccount = (a: string, z: string) => {
  const _brokerageAccountOrder = [
    '楽天証券',
    'SBI',
    '三菱MS(WM)',
    '三菱MS',
    '日興証券',
    '大和証券',
    '野村証券',
    'みずほ証券',
    'CS',
    'UBS',
    '東海東京証券',
    '三菱UFJ銀行',
    'みずほ銀行',
    '三井住友銀行',
    'りそな銀行',
    'マネックス証券',
    'あかつき証券',
    'HiJoJo Partners',
    'ジュリアス・ベア',
    'その他',
  ];
  return _brokerageAccountOrder.indexOf(a) - _brokerageAccountOrder.indexOf(z);
};

const compareFnCurrency = (a: string, z: string) => {
  const _currencyOrder = [
    'JPY',
    'USD',
    'EUR',
    'GBP',
    'AUD',
    'NZD',
    'BRL',
    'MXN',
    'TRY',
    'RUB',
    'CNH',
    'CNY',
    'ZAR',
    'IND',
    'IDR',
    'CHF',
    'CAD',
    'HKD',
    'ARS',
    'SGD',
    'THB',
    'TWD',
    'LRW',
    'NOK',
  ];
  return _currencyOrder.indexOf(a) - _currencyOrder.indexOf(z);
};

const compareFnProductType = (a: string, z: string) => {
  const _productTypeOrder = [
    '債券',
    '株式',
    'ETF',
    '投資信託',
    'ファンド',
    '非上場株式',
    '不動産',
    'オルタナティブ',
    'キャッシュ',
    'その他',
  ];
  return _productTypeOrder.indexOf(a) - _productTypeOrder.indexOf(z);
};

const makeOptions = (
  fieldName: '証券口座' | '資産クラス' | '通貨',
  rows: IAsset[],
  mapFn: (value: IAsset, index: number, array: IAsset[]) => string,
  compareFn: (a: string, z: string) => number
) => {
  const options: TableFilterOption[] = rows
    .map(mapFn)
    .filter((v, i, arr) => arr.indexOf(v) === i)
    .sort(compareFn)
    .map(row => ({ name: row, field: fieldName }));
  return options;
};

export const AssetFilter = React.memo(
  ({ allRows, setTableFilterOptions }: AssetFilterProps) => (
    <Autocomplete
      multiple
      options={[
        ...makeOptions(
          '証券口座',
          allRows,
          row => row.brokerageAccount,
          compareFnBrokerageAccount
        ),
        ...makeOptions(
          '資産クラス',
          allRows,
          row => row.productType,
          compareFnProductType
        ),
        ...makeOptions('通貨', allRows, row => row.currency, compareFnCurrency),
      ]}
      onChange={(event, values) => setTableFilterOptions(values)}
      groupBy={option => option.field}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={isTableFilterOptionEquals}
      renderOption={(props, option, { selected }) => {
        const { key: _key, ...restProps } = props; // Remove key from props
        return (
          <li key={option.name} {...restProps}>
            {option.name}
          </li>
        );
      }}
      
      noOptionsText="候補が見つかりません"
      sx={{ flexGrow: 1, marginRight: 2 }}
      renderInput={params => (
        <TextField
          {...params}
          label="絞り込みたい項目を選んでください"
          variant="outlined"
          size="small"
        />
      )}
    />
  )
);

const isTableFilterOptionEquals = (
  a: TableFilterOption,
  b: TableFilterOption
) => {
  return a.name === b.name && a.field === b.field;
};
