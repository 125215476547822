import React from 'react';
import { useAppSelector } from 'app/hooks';
import { DashboardPresentation } from 'components/Dashboard';
import { Loading } from 'components/Loading';
import { selectClient, selectClientFinancialSummary, selectClientLatestMentoringSheet, selectClientMentoringSheet, selectClientStatus } from 'features';
import { useInsightSheet } from 'hooks';

import { useParams } from 'react-router-dom';

export const AdminClientDashboardPage = () => {
  const { clientId } = useParams();
  const client = useAppSelector(selectClient)
  const clientStatus = useAppSelector(selectClientStatus)
  const clientFinancialSummary = useAppSelector(selectClientFinancialSummary)
  const mentoringSheet = useAppSelector(selectClientLatestMentoringSheet);

  // const [{ status, error, summary }, { fetchSummary }] = useClient();
  const { insight: commonInsight } = useInsightSheet('common');
  const { insight: indInsight } = useInsightSheet(clientId || '');

  if (clientStatus === 'idle' || clientStatus === 'pending') {
    return <Loading />;
  }
  if (clientStatus === 'rejected') {
    return <p>資産総額の取得に失敗しました</p>;
  }
  if (mentoringSheet === null) {
    return (
      <div>まだシートが登録されておりません。担当にお問い合わせください。</div>
    );
  }

  return (
    <DashboardPresentation
      sheet={mentoringSheet}
      clientName={client?.name || 'お客'}
      totalMarketValueDomestic={clientFinancialSummary?.marketValueDomestic ?? 0}
      personalizedInsightSpeakerDeckIFrame={indInsight?.speakerDeckIFrame || ''}
      commonInsightSpeakerDeckIFrame={commonInsight?.speakerDeckIFrame || ''}
    />
  );
};
