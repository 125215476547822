import { useCallback, useMemo, useState } from 'react';
import {
  Dialog,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { PasswordTextField } from 'parts/PasswordTextField';
import { Family, } from 'types';
import { addClient } from 'api';

interface AddClientDialogProps {
  open: boolean;
  onClose: () => void;
  familyOptions: Family[];
  maxPortpholiohIdNumber: number;
}

export const AddClientDialog = ({
  open,
  onClose,
  familyOptions,
  maxPortpholiohIdNumber,
}: AddClientDialogProps) => {
  const initialFormState = useMemo(
    () => ({
      name: 'P' + (maxPortpholiohIdNumber + 1).toString().padStart(4, '0'),
      email: '',
      password: '',
      familyId: undefined,
      familyName: undefined,
      status: '',
      clientBillingId: '',
    }),
    [maxPortpholiohIdNumber]
  );

  const [formState, setFormState] = useState<{
    name: string;
    email: string;
    password: string;
    familyId?: string;
    familyName?: string;
    status: string;
    clientBillingId: string;
  }>(initialFormState);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleAddClient = useCallback(async () => {
    setIsLoading(true);
    try {
      await addClient(formState);
      setFormState(initialFormState);
      onClose();
      setIsError(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [formState, initialFormState, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle sx={{ mx: 4, mt: 2 }}>顧客新規登録</DialogTitle>
      <DialogContent sx={{ mx: 4 }}>
        <div>
          <TextField
            type="text"
            label="P王番号"
            value={formState.name}
            autoFocus
            autoComplete="off"
            sx={{ my: 2 }}
            variant="standard"
            onChange={event => {
              setFormState(prev => ({ ...prev, name: event.target.value }));
            }}
            fullWidth
          />
        </div>
        <div>
          <TextField
            type="text"
            label="メールアドレス"
            value={formState.email}
            autoComplete="off"
            sx={{ my: 2 }}
            variant="standard"
            onChange={event => {
              setFormState(prev => ({ ...prev, email: event.target.value }));
            }}
            fullWidth
          />
        </div>
        <div>
          <PasswordTextField
            label="パスワード（6文字以上）"
            value={formState.password!}
            id="new-client-password-input"
            onChange={event => {
              setFormState(prev => ({
                ...prev,
                password: event.target.value,
              }));
            }}
            inputProps={{ minLength: 6 }}
            sx={{ my: 2 }}
            variant="standard"
            autoComplete="new-password"
            fullWidth
          />
        </div>
        <div>
          {/* TODO: AutoComplete  */}
          <Autocomplete
            options={familyOptions}
            renderInput={params => (
              <TextField
                {...params}
                type="text"
                label="ファミリー"
                variant="standard"
              />
            )}
            freeSolo
            // @ts-ignore
            getOptionLabel={option => option.name}
            onInputChange={(event, value) => {
              setFormState(prev => {
                if (prev.familyName === value) {
                  return prev;
                }
                return { ...prev, familyName: value, familyId: undefined };
              });
            }}
            value={
              formState.familyId && formState.familyName
                ? { id: formState.familyId, name: formState.familyName }
                : null
            }
            onChange={(event, value) => {
              if (typeof value === 'string') {
                return; // never
              }
              if (value === null) {
                setFormState(prev => ({
                  ...prev,
                  familyName: undefined,
                  familyId: undefined,
                }));
              } else {
                setFormState(prev => ({
                  ...prev,
                  familyName: value.name,
                  familyId: value.id,
                }));
              }
            }}
            sx={{ my: 2 }}
          />
        </div>
        <div>
          <FormControl variant="standard" sx={{ my: 2, width: '200px' }}>
            <InputLabel id="client-status-select-label">ステータス</InputLabel>
            <Select
              labelId="client-status-select-label"
              value={formState.status}
              label="ステータス"
              onChange={event => {
                setFormState(prev => ({
                  ...prev,
                  status: event.target.value as
                    | '顧客'
                    | 'リード'
                    | 'デモ'
                    | '解約済み',
                }));
              }}
            >
              {['顧客', 'リード', 'デモ', '解約済み'].map(status => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <TextField
            type="text"
            label="請求先番号"
            value={formState.clientBillingId}
            autoComplete="off"
            sx={{ my: 2 }}
            variant="standard"
            onChange={event => {
              setFormState(prev => ({
                ...prev,
                clientBillingId: event.target.value,
              }));
            }}
            fullWidth
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', mx: 6, mb: 4 }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="secondary"
          // disabled={name.length === 0 || email.length === 0 || password.length < 6}
          onClick={handleAddClient}
        >
          {isLoading ? '登録中...' : '新規登録'}
        </Button>
      </DialogActions>
      {isError && (
        <div style={{ textAlign: 'right', margin: 12, color: 'red' }}>
          新規登録できませんでした
        </div>
      )}
    </Dialog>
  );
};
