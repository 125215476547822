import React from 'react';
import { Autocomplete, Box, Grid, Stack, TextField } from '@mui/material';
import {
  BrokerageAccountField,
  AccountTypeField,
  CurrencyField,
  ProductTypeAltField,
  UnitField,
  NumericalTextField,
  StringTextField,
  CacheUnitField,
} from './DialogParts';
import {
  INumericalFieldProps,
  ITextFieldProps,
  ISelectProps,
} from './customHooks';
import { FinancialProduct } from 'types';

interface ICacheAdditionalPanel {
  productNameProps: ITextFieldProps;
  currencyProps: ISelectProps;
  unitProps: ISelectProps;
  brokerageAccountProps: ISelectProps;
  amountProps: INumericalFieldProps;
}
interface IIsinAdditionalPanel {
  brokerageAccountProps: ISelectProps;
  accountTypeProps: ISelectProps;
  amountProps: INumericalFieldProps;
  purchasePriceProps: INumericalFieldProps;
  paymentLocalProps: INumericalFieldProps;
  paymentDomesticProps: INumericalFieldProps;
  distributionLocalProps: INumericalFieldProps;
  salesProceedsLocalProps: INumericalFieldProps;
  salesProceedsDomesticProps: INumericalFieldProps;
  financialProducts: FinancialProduct[] | undefined;
  selectedProduct: FinancialProduct | undefined;
  setSelectedProduct: (value: FinancialProduct | undefined) => void;
  isProductSelectedError: boolean;
  setIsProductSelectedError: (value: boolean) => void;
}

interface IAlternativeAdditionalPanel {
  productNameProps: ITextFieldProps;
  currencyProps: ISelectProps;
  unitProps: ISelectProps;
  productTypeProps: ISelectProps;
  marketPriceProps: INumericalFieldProps;
  calcFlagProps: INumericalFieldProps;
  brokerageAccountProps: ISelectProps;
  amountProps: INumericalFieldProps;
  purchasePriceProps: INumericalFieldProps;
  paymentLocalProps: INumericalFieldProps;
  paymentDomesticProps: INumericalFieldProps;
  distributionLocalProps: INumericalFieldProps;
  salesProceedsLocalProps: INumericalFieldProps;
  salesProceedsDomesticProps: INumericalFieldProps;
}

const IsinAdditionPanel = ({
  brokerageAccountProps,
  accountTypeProps,
  amountProps,
  purchasePriceProps,
  paymentLocalProps,
  paymentDomesticProps,
  distributionLocalProps,
  salesProceedsLocalProps,
  salesProceedsDomesticProps,
  financialProducts,
  selectedProduct,
  setSelectedProduct,
  isProductSelectedError,
  setIsProductSelectedError,
}: IIsinAdditionalPanel) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <div>
            {financialProducts ? (
              <>
                <Autocomplete<FinancialProduct>
                  disablePortal
                  id="isin-field"
                  options={financialProducts}
                  getOptionLabel={option =>
                    `${option.manual_name}, ${option.isin}, ${option.crncy}, ${option.ticker}`
                  }
                  getOptionKey={option => option.id}
                  sx={{ width: '100%' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="銘柄選択*(証銘柄名, ISIN, 通貨. Tickerで検索)"
                      error={isProductSelectedError}
                      helperText={
                        isProductSelectedError ? '銘柄を選択してください' : ''
                      }
                    />
                  )}
                  onChange={(event, value) => {
                    setSelectedProduct(value ?? undefined);
                    setIsProductSelectedError(!value);
                  }}
                  value={selectedProduct}
                  isOptionEqualToValue={(option, value: FinancialProduct|undefined) => option.id === value?.id}
                />
                <Box
                  sx={{
                    my: 2,
                    backgroundColor: '#F2F2F2',
                    borderRadius: 1,
                    p: 2,
                  }}
                >
                  <div>
                    <span style={{ color: '#4D4D4D', fontWeight: 'bold' }}>
                      銘柄情報&nbsp;
                    </span>
                    <span style={{ color: 'red' }}>
                      {selectedProduct ? '' : '銘柄を選択してください'}
                    </span>
                  </div>
                  <hr />
                  <div>
                    <span style={{ color: '#4D4D4D', fontWeight: 'bold' }}>
                      ISIN・証券コード:&nbsp;
                    </span>
                    {selectedProduct?.isin}
                  </div>
                  <div>
                    <span style={{ color: '#4D4D4D', fontWeight: 'bold' }}>
                      銘柄名:&nbsp;
                    </span>
                    {selectedProduct?.manual_name}
                  </div>
                  <div>
                    <span style={{ color: '#4D4D4D', fontWeight: 'bold' }}>
                      通貨:&nbsp;
                    </span>
                    {selectedProduct?.crncy}
                  </div>
                  <div>
                    <span style={{ color: '#4D4D4D', fontWeight: 'bold' }}>
                      単位:&nbsp;
                    </span>
                    {selectedProduct?.manual_unit}
                  </div>
                  <div>
                    <span style={{ color: '#4D4D4D', fontWeight: 'bold' }}>
                      資産クラス:&nbsp;
                    </span>
                    {selectedProduct?.manual_class}
                  </div>
                </Box>
              </>
            ) : (
              <p>読み込み中・・・</p>
            )}
          </div>
          <BrokerageAccountField {...brokerageAccountProps} />
          <AccountTypeField {...accountTypeProps} />
          <NumericalTextField label="数量*" {...amountProps} />
          <NumericalTextField label="買付単価*" {...purchasePriceProps} />
          <NumericalTextField
            label="買付受払金額(現地)*"
            {...paymentLocalProps}
          />
          <NumericalTextField
            label="買付受払金額(円換算)*"
            {...paymentDomesticProps}
          />
          <NumericalTextField
            label="利金配当金計(現地)"
            {...distributionLocalProps}
          />
          <NumericalTextField
            label="売却代金(現地)"
            {...salesProceedsLocalProps}
          />
          <NumericalTextField
            label="売却代金(円換算)"
            {...salesProceedsDomesticProps}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

const AlternativeAdditionPanel = ({
  productNameProps,
  currencyProps,
  unitProps,
  productTypeProps,
  marketPriceProps,
  calcFlagProps,
  brokerageAccountProps,
  amountProps,
  purchasePriceProps,
  paymentLocalProps,
  paymentDomesticProps,
  distributionLocalProps,
  salesProceedsLocalProps,
  salesProceedsDomesticProps,
}: IAlternativeAdditionalPanel) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <StringTextField label="銘柄名*" {...productNameProps} />
          <CurrencyField {...currencyProps} />
          <UnitField {...unitProps} />
          <ProductTypeAltField {...productTypeProps} />
          <NumericalTextField label="時価*" {...marketPriceProps} />
          <NumericalTextField label="計算式フラグ" {...calcFlagProps} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <BrokerageAccountField {...brokerageAccountProps} />
          <NumericalTextField label="数量*" {...amountProps} />
          <NumericalTextField label="買付単価*" {...purchasePriceProps} />
          <NumericalTextField
            label="買付受払金額(現地)*"
            {...paymentLocalProps}
          />
          <NumericalTextField
            label="買付受払金額(円換算)*"
            {...paymentDomesticProps}
          />
          <NumericalTextField
            label="利金配当金計(現地)"
            {...distributionLocalProps}
          />
          <NumericalTextField
            label="売却代金(現地)"
            {...salesProceedsLocalProps}
          />
          <NumericalTextField
            label="売却代金(円換算)"
            {...salesProceedsDomesticProps}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

const CacheAdditionPanel = ({
  productNameProps,
  currencyProps,
  unitProps,
  brokerageAccountProps,
  amountProps,
}: ICacheAdditionalPanel) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <StringTextField label="名称*" {...productNameProps} />
          <CurrencyField {...currencyProps} />
          <CacheUnitField {...unitProps} />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <BrokerageAccountField {...brokerageAccountProps} />
          <NumericalTextField label="数量*" {...amountProps} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export { IsinAdditionPanel, AlternativeAdditionPanel, CacheAdditionPanel };
