import { Box, Grid } from '@mui/material';

interface AlignCenterGridProps { }
export const AlignCenterGrid: React.FC<{ children: React.ReactNode } & AlignCenterGridProps> = ({
  children,
}) => (
  <Box
    display="flex"
    justifyContent="center"
    sx={{ backgroundColor: '#f7fcff', pt: 4 }}
  >
    <Grid>{children}</Grid>
  </Box>
);
