import { MaintenanceInfo } from 'components/MaintenanceInfo';
import { useAuth, useRedirectIfAdmin } from 'hooks';
import bgImg from 'assets/loginbg2.png';
import { LinkToForgotPassword } from 'components/LinkToForgotPassword';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Link,
  SxProps,
} from '@mui/material';
import { useState } from 'react';
import { LogoTop } from 'parts/LogoTop';

const LinKToHelp = ({ sx }: { sx?: SxProps }) => (
  <Box sx={{ display: 'flex', ...sx }} justifyContent="center">
    <Link
      href="https://knowledge.pragmaworks.co.jp/アカウント-セキュリティ"
      variant="caption"
    >
      お困りですか？
    </Link>
  </Box>
);


export const AdminSignInPage = () => {
  const { signIn, status, error } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  useRedirectIfAdmin();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item>
          <Paper elevation={3} sx={{ width: 300, p: 5 }} component="form"
            onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              await signIn(email, password);
            }}>
            <LogoTop />
            <Box
              sx={{ textAlign: 'center', mt: 1, mb: 5 }}
              dangerouslySetInnerHTML={{ __html: 'ポートフォリ王 &reg;' }}
            />

            <Box sx={{ mb: 2 }}>
              <TextField
                onChange={event => setEmail(event.target.value)}
                variant="outlined"
                value={email}
                size="small"
                label="メールアドレス"
                fullWidth
                type="text"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                }}
                variant="outlined"
                value={password}
                size="small"
                label="パスワード"
                fullWidth
                helperText={error !== null ? error : ''}
                error={error !== null}
                type="password"
                autoComplete={'current-password'}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                type="submit"
                disabled={status === 'loading'}
              >
                {status === 'loading' ? (
                  <CircularProgress sx={{ color: '#fff' }} />
                ) : (
                  'ログイン'
                )}
              </Button>
            </Box>
            <MaintenanceInfo />
            <LinkToForgotPassword sx={{ mt: 4 }} />
            <LinKToHelp sx={{ mt: 1 }} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
