import React from 'react';
import { Box } from '@mui/material';
import {
  filterAssets,
  useAssetFilter,
  AssetTable,
  useAssetTableSorter,
  FinancialProductDetailDialog,
  useFinancialProductDetailDialog,
  Loading,
  AssetFilter,
  AssetTableToggle,
} from 'components';
import {
  useDownloadExcel,
  useSnackbar,
  useToggle,
} from 'hooks';
import { AssetAdditionDialog } from './AssetAdditionDialog';
import { AssetMenu } from './AssetMenu';
import { DelProductDialog } from './DelProductDialog';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { clientSlice, createAssetAsync, removeAssetAsync, replaceAssetAsync, selectClient, selectClientAssets, selectClientError, selectClientStatus, selectFinancialProducts } from 'features';
import { useAppContext } from 'contexts';

export const AssetsPage = () => {
  const dispatch = useAppDispatch();
  const client = useAppSelector(selectClient);
  const clientStatus = useAppSelector(selectClientStatus);
  const clientError = useAppSelector(selectClientError);
  const clientAssets = useAppSelector(selectClientAssets);
  const financialProducts = useAppSelector(selectFinancialProducts)
  const [detailVisible, toggleDetailVisible] = useToggle(false);
  const [soldVisible, toggleSoldVisible] = useToggle(false);
  const { order, orderBy, buildSorter } = useAssetTableSorter();
  const { draftAsset, setDraftAsset } = useAppContext();
  const [open, product, isLoadingDetail, detailError, action] =
    useFinancialProductDetailDialog();
  const [openAddProduct, toggleOpenAddProduct] = useToggle(false);
  const [openDelProduct, toggleOpenDelProduct] = useToggle(false);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [filterOptions, setFilterOptions] = useAssetFilter();
  const [downloadExcelState, { downloadExcelByClientId }] = useDownloadExcel();

  const showAddSuccessSnackbar = React.useCallback(() => {
    showSuccessSnackbar(`追加しました`);
  }, []);
  const showEditSuccessSnackbar = React.useCallback(() => {
    showSuccessSnackbar(`編集しました`);
  }, []);
  const showAssetErrorSnackbar = React.useCallback(() => {
    showErrorSnackbar('エラーが発生しました。');
  }, []);
  const downloadClientExcel = () => {
    if (!client) throw new Error('clientId is undefined');
    return downloadExcelByClientId(client.id, orderBy, order);
  };
  const filterdAssets = filterAssets(clientAssets, filterOptions);

  if (!client) {
    return <Loading />
  }

  return (
    <>
      <Box sx={{ px: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
          <AssetMenu
            selectedAsset={draftAsset}
            toggleOpenAddProduct={toggleOpenAddProduct}
            toggleOpenDeleteProduct={toggleOpenDelProduct}
            downloadExcel={downloadClientExcel}
            downloadExcelStatus={downloadExcelState.status}
            downloadExcelErrorMessage={downloadExcelState.errorMessage}
          />
          <AssetFilter
            allRows={filterdAssets}
            setTableFilterOptions={setFilterOptions}
          />
          <AssetTableToggle
            toggleDetailVisible={toggleDetailVisible}
            toggleSoldVisible={toggleSoldVisible}
          />
        </Box>
        {clientStatus === 'pending' ? (
          <Loading />
        ) : clientStatus === 'rejected' ? (
          clientError
        ) : (
          <AssetTable
            selectedAssets={draftAsset ? [draftAsset] : []}
            select={(selectedAsset) =>
              dispatch(clientSlice.actions.selectAsset(selectedAsset))
            }
            checkboxSelection
            onClickProductName={action.setBbgDocId}
            detailVisible={detailVisible}
            soldVisible={soldVisible}
            order={order}
            orderBy={orderBy}
            buildSorter={buildSorter}
            assets={filterdAssets}
          />
        )}
      </Box>
      <FinancialProductDetailDialog
        open={open}
        product={product}
        isLoading={isLoadingDetail}
        error={detailError}
        close={action.close}
      />
      <AssetAdditionDialog
        open={openAddProduct}
        closeDialog={toggleOpenAddProduct}
        showSuccessAddSnackbar={showAddSuccessSnackbar}
        showSuccessEditSnackbar={showEditSuccessSnackbar}
        showErrorSnackbar={showAssetErrorSnackbar}
        draftAsset={draftAsset}
        unselectProduct={() => setDraftAsset(null)}
        createAsset={({ clientId, data }) =>
          dispatch(createAssetAsync({ client_id: clientId, data })).unwrap()
        }
        putAsset={({ clientId, id, data }) =>
          dispatch(replaceAssetAsync({ client_id: clientId, id, data })).unwrap()
        }
        client={client}
        financialProducts={financialProducts}
      />
      {draftAsset && (
        <DelProductDialog
          open={openDelProduct}
          close={toggleOpenDelProduct}
          asset={draftAsset}
          unselect={() => setDraftAsset(null)}
          deleteAsset={({ clientId, id }) =>
            dispatch(removeAssetAsync({ client_id: clientId, id })).unwrap()
          }
          clientId={client.id}
        />
      )}
    </>
  );
};
